export const CATEGORY = Object.freeze({
  1: "모세오경",
  2: "역사서",
  3: "시가서",
  4: "예언서",
  5: "복음서",
  6: "바울서신",
  7: "공동서신",
  8: "요한계시록",
});
